.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

label {
  display: block;
  clear: both;
  margin: 10px 0;
}

label > input,
label > select {
  margin-left: 5px;
}

label > input {
  float: right;
}

input[type="submit"] {
  display: block;
  margin: 10px auto 0 auto;
}

.Result {
  font-size: 1.5em;
  font-weight: bold;
  margin: 10px 0;
}